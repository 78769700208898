import Typography from "typography"
import Wordpress2016 from "typography-theme-wordpress-2016"

// Wordpress2016.overrideThemeStyles = () => {
//   return {
//     "a.gatsby-resp-image-link": {
//       boxShadow: `none`,
//     },
//   }
// }

// delete Wordpress2016.googleFonts

// const typography = new Typography(Wordpress2016)

const typography = new Typography({
  baseFontSize: "18px",
  baseLineHeight: 1.45,
  headerFontFamily: [
    "Caveat",
    "Helvetica Neue",
    "Segoe UI",
    "Helvetica",
    "Arial",
    "sans-serif",
  ],
  headerColor: "#827463",
  bodyFontFamily: ["Crimson Text", "serif"],
  overrideStyles: ({ adjustFontSizeTo, rhythm }, options, styles) => ({
    // h1: {
    //   fontFamily: ["Montserrat", "sans-serif"].join(","),
    // },
    // blockquote: {
    //   ...adjustFontSizeTo("19px"),
    //   color: gray(41),
    //   fontStyle: "italic",
    //   paddingLeft: rhythm(13 / 16),
    //   marginLeft: rhythm(-1),
    //   borderLeft: `${rhythm(3 / 16)} solid ${gray(10)}`,
    // },
    // "blockquote > :last-child": {
    //   marginBottom: 0,
    // },
    a: {
      textDecoration: "none",
    },
    "p a": {
      textDecoration: "underline",
    },
  }),
})

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
