import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { SocialIcon as ReactSocialIcon } from "react-social-icons"

import { rhythm, scale } from "../utils/typography"

const Background = styled.div`
  background-color: #fbfaf5;
  color: #e2dcce;
  background-image: url("/paper.png");
  min-height: 100vh;
`

const Page = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: ${rhythm(24)};
  padding: ${rhythm(1.5)} ${rhythm(3 / 4)};
  background-color: #fbfaf5;
  color: black;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const Footer = styled.footer`
  margin-top: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
  padding-top: 0.4rem;
  /* padding: 0.4rem 0 0.4rem 1rem;
  border-left: 2px solid #222; */

  p {
    margin: 0 0 0.4rem;
    padding: 0;
  }

  span {
    /* text-transform: uppercase; */
    font-size: 0.8rem;
    margin-bottom: 1rem;
  }

  .social-icons {
    display: flex;
  }
`

const SocialIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
`

const SocialIcon = ({ url }) => (
  <SocialIconContainer>
    <ReactSocialIcon
      url={url}
      bgColor="#222"
      fgColor="#eee"
      style={{ height: "100%", width: "100%" }}
    />
  </SocialIconContainer>
)

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  let header

  if (location.pathname === rootPath) {
    header = (
      <h1
        style={{
          ...scale(1),
          marginBottom: rhythm(1.5),
          marginTop: 0,
          fontWeight: 800,
          padding: "0.05rem 0.4rem",
          color: "#596E79",
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          {title}
        </Link>
      </h1>
    )
  } else {
    header = (
      <h3
        style={{
          fontWeight: 800,
          marginTop: 0,
          padding: "0.05rem 0.4rem",
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          {title}
        </Link>
      </h3>
    )
  }
  return (
    <Background
    // className="pattern-cross-dots-sm"
    >
      <Page>
        <Header>{header}</Header>
        <main>{children}</main>
        <Footer>
          <p>—</p>
          <p>
            Christopher Mjelde © {new Date().getFullYear()}. You can email me at
            xxx@gmail.com
          </p>
        </Footer>
      </Page>
    </Background>
  )
}

export default Layout
